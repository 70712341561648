<template>
  <div class="container">
    <div style="display:flex;">
      <h5 style="margin:auto;">Budgeting</h5>
    </div>
    <hr>

    <div class="row">
      <div class="">
        <ul class="list2">
          <li class="user listitem flex clickable" v-for="(member) in members" :key="member.ID" @click="goToTransactions(member.BA_ID)">
            <div class="d-flex flex userDiv">
              <div class='avatar'><img :src="member.AVATAR_URL" alt="avatar"></div>
              <div class="info">
                <div class="topLine">
                  <div>{{member.FULL_NAME}}</div>

                </div>
                <div class="d-flex flex bottomLine">
                  <div class="inout in"><span class="inTxt">in</span>{{member.AMOUNT_IN}}</div>
                  <div class="inout out"><span class="outTxt">out</span>{{member.AMOUNT_OUT}}</div>
                </div>

              </div>
              <div :class="numberColor(member.AMOUNT)" class="sd">{{member.AMOUNT}}</div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="row">
      <div class="d-flex flex periodOptions">
        <div v-for="periodOption in periodOptions" :key="periodOption.id" class="periodOption clickable" :class="{active:periodOption.id===periodOptionActiveId}" @click="selectPeriodOption(periodOption)">{{periodOption.name}}</div>
      </div>
    </div>


  </div>


</template>

<script>

export default {
  name: 'Family',
  components: {

  },
  data(){
    return{
      members:null,
      familyName:'',
      activeIndex: null,
      activeUserId: null,
      showDeleteModal:false,
      periodOptions: [
        {id:1, name:'1M',months:1},
        {id:2, name:'3M',months:3},
        {id:3, name:'6M',months:6},
        {id:4, name:'1Y',months:12},
        {id:5, name:'ALL',months:1000},
      ],
      periodOptionActiveId:2,
      months:3
    }
  },
  methods:{
    getFamilyMembers: function(){
      let loader = this.$loading.show();
      this.axios
          .post(this.$hostApi,{task:'getFamilyBudgetSummary', months:this.months},{
            headers:{
              'token':localStorage.getItem('user')
            }
          })
          .then(response =>{
            this.members = response.data;
            //console.log(response);
            loader.hide();
          })
          .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
          })
    },

    goToTransactions:function(baId){
      this.$root.playClickSound();
      this.$root.gotoRoute({ path: '/budget/tran', query: { baId: baId } })
    },

    selectPeriodOption:function(periodOption){
      this.$root.playClickSound();
      this.periodOptionActiveId = periodOption.id;
      this.months = periodOption.months;
      this.getFamilyMembers();
    },

    numberColor:function(num){
      return num < 0 ? 'negative' : '';
    },

  },
  mounted() {
    document.title = "Budget";
    this.getFamilyMembers();
  }
}
</script>

<style scoped>

.list2 li{

  margin:3px 5px;

  align-items: center;
  border:2px solid #fff;
}

.avatar{
  margin-left:5px;
}

.list2 .avatar img{
  width:40px;
  height:40px;
  border:2px solid #9c9c9c;
  border-radius: 50%;
  padding:3px;
}

.list2 li.active{
  border:2px solid #0e90d2;
}

.list2 li{
  position:relative;
  background-color: #f5f5f5;
}

.email{
  font-size:12px;
}




.selectDiv{
  position:absolute;
  right:10px;
  width:50px;
}

.selectSpot{
  color:#fff;
}

li.active .selectSpot{
  color: #0e90d2;
}

.confirmDeletionModal .modal-dialog{
  position:fixed;
  bottom:57px;
  width:100%;
  margin:0;
}

.flex {
  display: flex;
  justify-content: left;
  align-items: center;
}

#actionBtnsContainer {
  position: fixed;
  bottom: 100px;
  right: 50px;
  height: 45px;
  color: #fff;
}

#actionBtnsDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.actionDiv {
  margin-left: 10px;

}

.actionBtn {
  width: 45px;
  height: 45px;
  background-color: #0dcaf0;
  border-radius: 50%;
  color: #fff;
  font-size: 16px;
  text-align: center;
  display:flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
}

.actionTxt {
  color: #3b3b3b;
  font-size: 8px;
  height: 10px;
  text-align: center;
}

.modal-dialog.fullwidth {
  width: 100% !important;
  max-width: 100% !important;
}

.delYes {
  background-color: red;
  color: #fff;
}

.delConfirmBtn {
  text-align: center;
  border: 1px solid #f5f5f5;
  margin: 10px;
}

.delNo {
  background-color: #e4e4e4;
  color: #3b3b3b;
}

.delConfirmBtn svg {
  margin-right: 10px;
}

.userDiv{
  width:100%;
}

.info{
  flex-grow:1;
  font-size:12px;
}

.topLine{
  padding:3px 10px;
  display:flex;
  justify-content: space-between;
  font-weight: bold;
  margin-bottom:2px;
}

.bottomLine{
  width:100%;
  padding:3px 10px;
}

.in{
  width:110px;

}

.inout span{
  display:inline-block;
  padding:1px;
  width:30px;
  text-align: center;
  border-radius: 3px;
  color:white;
  margin-right:5px;
}

.inTxt{
  background-color: #42b983;

}

.outTxt{
  background-color: red;
}

.sd{
  font-size:16px;
  padding-right:10px;
}

.periodOptions{
  width:100%;
  display:flex;
  justify-content: center;
  margin:10px 2px 20px 2px;
}

.periodOption.active{
  background-color: #3b3b3b;
  color:white;
}

.periodOptions div{
  font-size:12px;
  background-color: #f5f5f5;
  padding:5px 10px;
  margin:3px;
  border-radius:5px;
  width:50px;
  text-align: center;
}

</style>